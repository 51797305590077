import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useEffect } from 'react'

function genRandomString(length) {
  var randomCharset =
    'abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  var random = ''

  for (var c = 0, cl = randomCharset.length; c < length; ++c) {
    random += randomCharset[Math.floor(Math.random() * cl)]
  }

  return random
}

export default function Index() {
  const handleLogin = () => {
    const nonce = genRandomString(64)
    const redir = process.env.NEXT_PUBLIC_OKTA_REDIRECT
    window.location.href =
      `https://${process.env.NEXT_PUBLIC_OKTA_DOMAIN}/v1/authorize?client_id=${process.env.NEXT_PUBLIC_OKTA_CLIENTID}&nonce=${nonce}&state=login&scope=openid profile email&response_type=id_token token&redirect_uri=` +
      encodeURIComponent(redir)
  }

  const src = 'nPOS.png'

  return (
    <Container maxWidth="md">
      <img alt="nPOS" src={src} width="160" height="200" />
      <Grid my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          nChannel Point of Sale
        </Typography>
        <Grid>
          <Typography variant="h6" component="h1" gutterBottom>
            This system uses GSA FASID for log in and multi-factor
            authentication
          </Typography>
          <Button
            id="loginBtn"
            name="loginBtn"
            onClick={handleLogin}
            variant="contained"
          >
            LOG IN
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
